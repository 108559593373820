html, body {
  height: 100%;
  margin: 0;
}

/* Full-page background container */
.background-container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background: url('/public/Images/robot.avif') center/cover no-repeat, #f0f0f0; /* Fallback color */
  overflow: hidden;
}

.background-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.text-over {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
}

.content {
  padding: 1.25rem;
}

h1 {
  text-align: center;
  margin: 0 0 0.3rem;
  width: 100%;
}

p {
  margin: 0;
  padding: 0 15rem; /* Adjusted padding for larger screens */
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  p {
    padding: 0 6.25rem; /* Adjust for tablets */
  }
}

@media (max-width: 768px) {
  h1 {
    margin-bottom: 0.2rem;
  }
  
  p {
    padding: 0 1.25rem;
    line-height: 1.4;
  }
}

h2, h3 {
  margin: 0.625rem;
  line-height: 1.5;
  font-weight: bold;
  font-size: 1.25rem;
  color: brown;
}

/* Button styles */
.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
}

.center-button {
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
}

.center-button:hover,
.center-button:active {
  background-color: red !important;
}

/* Footer styles */
.footer {
  text-align: center;
  margin-top: 1.25rem;
}

.footer p {
  margin: 0;
  padding: 0.625rem 0;
  background-color: #282c34;
  color: white;
}
