.event-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 20px;
}

.event-card {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 150px;
  width: 50px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.event-card:hover {
  transform: scale(1);
}

.event-icon {
  font-size: 1.5em;
  color: #3498db;
  margin-bottom: 1px;
}

.event-card p {
  font-size: 3px;
  color: #333;
}
/* Grid layout for event list */
.event-grid {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
gap: 20px;
padding: 20px;
}

/* Event card styling */
.event-card {
background-color: #fff;
border: 1px solid #ddd;
border-radius: 8px;
padding: 20px;
text-align: center;
cursor: pointer;
transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}

/* Icon inside the event card */
.event-icon {
font-size: 2.5rem;
color: #333;
margin-bottom: 10px;
transition: color 0.3s;
}

/* Event name */
.event-card p4 {
font-size: 1.2rem;
color: #333;
font-weight: bold;
display: block;
margin-top: 10px;
}

/* Hover effect */
.event-card:hover {
transform: translateY(-5px); /* Adds a slight lift */
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
background-color: #f9f9f9;
}

.event-icon {
font-size: 5rem !important; 
color: black !important; /* Default icon color with higher specificity */
transition: color 0.3s ease; /* Smooth transition for color change */
}

.event-card:hover .event-icon {
color: blue !important; /* Icon color on hover with higher specificity */
}




.event-card:hover p4 {
color: #007bff; /* Changes text color on hover */
}


@media (max-width: 600px) {
  .event-card.active p4 {
    color: #007bff !important;
  }
  .event-card.active .event-icon {
    color: blue !important;
  }
}
